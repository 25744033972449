function init() {
    //add init later if desired
}

function log(error) {
    console.error(error);
}

export default {
    init,
    log
}
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";
import DocSign from "./components/DocSign/docsign";
import "./App.scss";

function App() {

  document.title = "SembraCare DocSign";
  return (
    <Router>
      <React.Fragment>
        <div class="container-fluid mainContainer">
          <Header />
          <div class="row content-row justify-content-center h-100">
            <div class="col-md-12 px-0 text-center">
              <Route exact path="/docSign/:docID" component={DocSign} />
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    </Router>
  );
}

export default App;

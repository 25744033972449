import React from "react";
import "./footer.scss";
import footerLogo from "../../images/PoweredBy-SembraCare.svg";

function Footer() {
  return (
    <footer className="footer">
      <img
        className="footer-img"
        alt="SembraCare Logo"
        src={footerLogo}
      ></img>
    </footer>
  );
}

export default Footer;

import React from "react";
import "./header.scss";
import Logo from "../../images/SembraCare-Logo.svg"


function Header() {
  return (
    <header className="header">
      <img
        className="header-img"
        alt="SembraTime Logo"
        src={Logo}
      ></img>
      {/* <h1>DocSign</h1> */}
    </header>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import SignaturePad from "react-signature-canvas";
import docService from "../../services/docService";
import "./docsign.scss";

function DocSign(props) {
  const [currDoc, setCurrDoc] = useState();
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [signatureError, setSignatureError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const docID = props.match.params.docID;
  let docSignaturePad = {};

  useEffect(() => {
    try {
      findDoc(docID);
    } catch (ex) { }
  }, []);

  const findDoc = async docID => {
    const { data: currentDoc } = await docService.findDoc(docID);
    setCurrDoc(currentDoc);
    try {
      if (currentDoc.docSignature) {
        setSignatureSaved(true);
      }
    } catch { }
  };

  const signatureClear = () => {
    docSignaturePad.clear();
  };

  const signatureSave = async () => {
    if (!docSignaturePad.isEmpty()) {
      setSignatureError(false);
      // setDocSignature(docSignaturePad.getTrimmedCanvas().toDataURL("image/jpeg"));
      const { data: saveResponse } = await docService.saveDocSignature(docID, docSignaturePad.getTrimmedCanvas().toDataURL("image/png"));
      if (saveResponse === "Signature Saved") {
        setCurrDoc({ ...currDoc, docSignature: docSignaturePad.getTrimmedCanvas().toDataURL("image/png") })
        setSignatureSaved(true);
      } else {
        setSignatureError(true);
        setErrorMessage(saveResponse);
      }
    } else {
      setSignatureError(true);
      setErrorMessage("Please Provide Signature");
    }
  };

  const closeWindow = () => {
    console.log("closing window")
    window.open('https://www.google.com/', "_self");
  }

  return (
    <div align="center" className="main-content px-0">
      {currDoc && (
        <React.Fragment>
          {/* <div > */}
          <div className="doc-sign-content container-fluid">
            <h1>{currDoc.docTitle}</h1>
            <div className="doc-info text-left">
              <p>Name: {currDoc.docOwner}</p>
              <p>Company: {currDoc.docCompany}</p>
              <hr />
              <p className="disclaimer">The signature below indicates my agreement with the associated document.  I agree that what I sign is representative of my signature.</p>
            </div>

            {/* <div className="disclaimer">
              <div className="doc-info text-left py-1 pl-2">Name: {currDoc.docOwner} <br /> Company: {currDoc.docCompany}</div>
              <div className="p-2">The signature below indicates my agreement with the associated document.  I agree that what I sign is representative of my signature.</div>
            </div> */}
            <div className="docSignBox" align="center">
              {!signatureSaved && (
                <React.Fragment>
                  <SignaturePad
                    canvasProps={{ className: "sigCanvas" }}
                    ref={ref => {
                      docSignaturePad = ref;
                    }}
                  />
                  {signatureError && (<h5 className="alert alert-danger m-1 p-1">{errorMessage}</h5>)}

                  <button className="custom-btn mr-5" onClick={signatureClear}>
                    Clear
                  </button>
                  <button className="custom-btn" onClick={signatureSave}>
                    Submit
                  </button>
                </React.Fragment>
              )}
              {signatureSaved && (
                <React.Fragment>
                  {/* <div style={{ className: "sigCanvas" }}><img alt="signature" src={currDoc.docSignature}></img></div> */}
                  <div className="alert alert-success">
                    <p>Signature Saved</p>
                    <p>For your security, please close this window and return to the Sign-up page</p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {/* </div> */}
        </React.Fragment>
      )}
    </div>
  );
}

export default DocSign;
import http from "./httpService";
import { apiUrl } from "../config.json";

export async function findDoc(docID) {
    const apiEndpoint = apiUrl + "/findDoc";
    const docResponse = await http.post(apiEndpoint,{docID:docID});
    return docResponse;
}

export async function saveDocSignature(docID, signature) {
    const apiEndpoint = apiUrl + "/saveDocSignature";
    const signatureResponse = await http.post(apiEndpoint,{docID:docID, docSignature:signature});
    return signatureResponse;
}


export default {
    findDoc,
    saveDocSignature
}